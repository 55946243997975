.signature {
    width: 100%;
    height: 100%
}

.navbar{
    background: #EDFBFE;
    height: 85px;
    opacity: 0.7;
    font-size: 1.2rem;
    transition: 0.5s;
    font-family: 'Roboto Slab';
}

.navbar.active {
    opacity: 0.97;
}

.nav-link {
    color: black !important;
    background: #EDFBFE;
    font-family: 'Roboto Slab';
    font-weight: bold;
    font-size: 1.5em;
    padding-right: 1rem!important;
    padding-left: 1rem!important;
}

@media screen and (max-width: 600px) {
    .nav-link {
        border-bottom: 1px solid #B8B9CB;
    }

    .signature {
        width: 200px;
    }
}

#contactMe {
    border-bottom:0;
} 

#navbarBrandSignature{
    width: 200px;
}