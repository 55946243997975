@font-face {
    font-family: 'Roboto Slab';
    src: local('Roboto Slab'), url(../fonts/Roboto_Slab/static/RobotoSlab-Light.ttf);
}

.professionalHistory {
    font-family: 'Roboto Slab';
    background-color: #D7E2BA;
    width: 100%;
}

#profHistoryDiv {
    padding-top: 40px;
    padding-bottom: 40px;
}

#areasOfExpertise {
    padding-top: 15px;
    padding-bottom: 15px;
    background-color: #91B987;
    border-radius: 25px;
}

#academicWork {
    padding-top: 15px;
}

#otherWork {
    padding-top: 15px;
    padding-bottom: 15px;
    background-color: #91B987;
    border-radius: 25px;
}

.title{
    font-weight:bold;
}
