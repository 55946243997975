.form {
    width: 100%;
    font-family: 'Roboto Slab';
}

.form-label {
    font-size: 1.5rem;
}

#colBtnSubmit {
    display: flex;
    align-items: center;
    justify-content: center;
}

@media screen and (min-width: 600px) {
    #textArea {
        width: 550px;
    }
}