.AboutMe {
    width: 100%;
    background: #EDFBFE;
    height: auto;
}

#aboutLabel {
    font-weight: bold;
}

#HeidiDescription {
    width: 100%;
    font-size: 1.2rem;
    font-family: 'Roboto Slab';
    font-weight: lighter;
    margin-left: 45px;
    padding-right: 0px;
}

#AboutMeColHeadshot {
    margin-top: 30px;
    margin-right: 45px;
    margin-bottom: 30px;
    padding-left: 0px;
    border-radius: 50%!important;
}

@media screen and (max-width: 600px) {
    .AboutMe {
        height: fit-content;
        width: 100%;
    }

    #HeidiDescription {
        margin-left: 0;
    }
}


